import { defineMessage, Trans } from "@lingui/macro"
import amplitude, { Identify } from "amplitude-js"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import ReactPixel from "react-facebook-pixel"
import styled from "styled-components"
import AndroidIcon from "~/assets/Android.svg"
import IosIcon from "~/assets/BlackIos.svg"
import FiveStars from "~/assets/FiveStars.png"
import { Loader } from "~/components/loader/loader"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
// import { cookies } from "~/services/cookies"
// import { i18nCustom } from "~/services/service/i18n-service"
// import { useAuthContext } from "~/subscriptions-web/context"
// import { usePaywall } from "../../use-paywall"

import AndroidLogoIcon from "./assets/AndroidLogo.svg"
import AppleLogoIcon from "./assets/AppleLogo.svg"
import ArrowLeftIcon from "./assets/ArrowLeft.svg"
import BlackLogoIcon from "./assets/BlackLogo.svg"
import FourHalfStarsIcon from "./assets/FourHalfStars.svg"
import FourHalfStarsNumberIcon from "./assets/FourHalfStarsNumber.svg"
import GreenCard from "./assets/GreenCard.svg"
import Ivan from "./assets/Ivan.png"
import OrangeCard from "./assets/OrangeCard.svg"
import PurpleCard from "./assets/PurpleCard.svg"
import QuoteIcon from "./assets/Quote.svg"
import RedCard from "./assets/RedCard.svg"
import Rosy from "./assets/Rosy.png"
import Sarah from "./assets/Sarah.png"

import {} from "../styles"

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* overflow: hidden; */
	box-sizing: border-box;
`

export const Logo = styled.img`
	height: 48px;
	width: auto;

	margin-top: 44px;
	/* margin-top: 24px; */
`

export const Title = styled.p`
	width: auto;
	text-align: center;
	box-sizing: border-box;

	display: inline-block;

	font-family: "Inter";
	font-style: normal;
	font-weight: 900;
	font-size: 32px;
	line-height: 36px;
	/* or 112% */
	display: flex;
	align-items: center;
	text-align: center;

	/* Light/Glyph/Third */
	color: #222222;

	margin: 16px 0 32px;
`

export const CallToAction = styled.p`
	width: auto;
	text-align: center;
	box-sizing: border-box;

	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	/* or 125% */
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.47px;

	color: #222222;

	margin: 0 0 20px;
`

export const Button = styled.div`
	margin: 4px 0;
	width: calc(100% - 40px);
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	background: #222222;
	border-radius: 32px;
	box-sizing: border-box;

	> img {
		width: auto;
		height: 32px;
	}

	> p {
		margin: 0 0 0 8px;

		font-family: "Inter";
		font-style: normal;
		font-weight: 800;
		font-size: 18px;
		line-height: 24px;
		/* identical to box height, or 133% */

		/* Light/Glyph/First Inverted */
		color: #ffffff;
	}
`

export const Rating = styled.div`
	display: flex;
	flex-direction: row;
	width: auto;
	height: 28px;

	margin: 30px 0 50px;
`

export const Downloads = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	margin-bottom: 48px;
	box-sizing: border-box;

	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 105px;
		margin: 0 15px;
		box-sizing: border-box;

		> p {
			font-family: Inter;
			font-style: normal;
			font-weight: 900;
			font-size: 32px;
			line-height: 32px;
			/* identical to box height, or 100% */
			text-align: center;
			letter-spacing: 0.5px;

			color: #333333;

			margin: 0;
		}

		> small {
			font-family: "Inter";
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			/* identical to box height, or 133% */
			text-align: center;

			color: rgba(34, 34, 34, 0.6);
		}
	}
`

export const Subtitle = styled.p`
	width: auto;
	text-align: center;

	font-family: "Inter";
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	/* identical to box height, or 125% */
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.47px;

	/* Light/Glyph/Third */
	color: #222222;

	margin: 0 0 16px;
`

export const Cards = styled.div`
	width: 100%;
	height: 250px;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-start;

	overflow-x: scroll;

	scroll-snap-type: x mandatory;
	scroll-snap-stop: always;

	margin-bottom: 32px;

	padding: 10px 0px;
	/* box-sizing: border-box; */

	scrollbar-width: none;

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
	/* Optional: show position indicator in red */
	::-webkit-scrollbar-thumb {
		background: transparent;
	}

	> img {
		scroll-snap-align: center;

		width: auto;
		height: 240px;
		margin: 0 4px;

		&:first-of-type {
			margin-left: 20px;
		}
		&:last-of-type {
			margin-right: 20px;
		}
	}
`

export const Reviews = styled.div`
	width: 100%;
	height: 370px;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-start;

	overflow-x: scroll;

	scroll-snap-type: x mandatory;
	scroll-snap-stop: always;

	margin-bottom: 16px;

	padding: 10px 0px;
	/* box-sizing: border-box; */

	scrollbar-width: none;

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
	/* Optional: show position indicator in red */
	::-webkit-scrollbar-thumb {
		background: transparent;
	}

	> div {
		scroll-snap-align: center;

		position: relative;
		min-width: 327px;
		height: 356px;

		background: #f2f2f2;
		border-radius: 24px;
		margin: 0 4px;

		padding: 38px 40px;
		box-sizing: border-box;

		&:first-of-type {
			margin-left: 20px;
		}
		&:last-of-type {
			margin-right: 20px;
		}

		> img {
			width: auto;
			height: 20px;

			margin-bottom: 30px;
		}

		> p {
			font-family: "Inter";
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			/* or 150% */

			margin: 0;

			/* Light/Glyph/Third */
			color: #222222;
		}

		> div {
			position: absolute;
			bottom: 24px;
			left: 40px;

			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;

			> img {
				width: auto;
				height: 32px;
				margin-right: 8px;
			}
			> p {
				font-family: "Inter";
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
				/* identical to box height, or 150% */

				margin: 0;

				/* Light/Glyph/Third */
				color: #222222;
			}
		}
	}
`

export const Arrows = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

export const Arrow = styled.div<{ isRotated?: boolean }>`
	transform: ${({ isRotated }) => isRotated && "rotate(180deg)"};
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.05);

	margin: 0 12px;

	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		width: auto;
		height: 16px;
	}
`

export const Footer = styled.p`
	margin: 8px 0 38px;
	/* margin: 32px 0 38px; */
	width: auto;
	text-align: center;

	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	/* identical to box height, or 154% */
	display: flex;
	align-items: center;
	text-align: center;

	/* Light/Glyph/Sixth */
	color: rgba(0, 0, 0, 0.4);
`

export function KiloPaywall() {
	const layout = useWindowLayout()
	// const onboardingType = useQueryParams().get("onboardingType")

	const windowSize = useWindowDimensions()

	// const shownReviews = useMemo(() => {
	// 	const reviewsByOnboardingType = onboardingType === "man" ? reviewsMan : reviews

	// 	return reviewsHidden ? reviewsByOnboardingType.slice(0, 3) : reviewsByOnboardingType
	// }, [reviewsHidden, onboardingType])

	const paywallForm = document.getElementById("paywall-form")

	// const history = useHistory()

	// const onSubscriptionSuccess = useCallback(() => {
	// 	history.push("/confirm")
	// }, [history])

	// const { currentUser } = useAuthContext()

	// const fbc = cookies.get("_fbc")
	// const fbp = cookies.get("_fbp")
	// const amplitudeDeviceId = amplitudeService.deviceId
	// const amplitudeUserId = amplitudeService.userId

	useEffect(
		() => {
			ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL!)
			amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY!)
		},
		[
			// paywallForm,
			// currentUser,
			// onSubscriptionSuccess,
			// amplitudeDeviceId,
			// amplitudeUserId,
			// fbc,
			// fbp,
			// product,
			// createPaddleSubscription,
		]
	)

	const onClickStore = () => {
		amplitude.getInstance().logEvent("[Plan] Click to store")
		ReactPixel.track("Purchase")
		ReactPixel.track("StartTrial")
		ReactPixel.track("InitiateCheckout")

		setTimeout(() => {
			window.open("https://glowbetest.onelink.me/QEDs/46zzsqi2", "_blank")
		}, 500)
	}

	return (
		<Wrapper>
			<Logo alt="" src={BlackLogoIcon} />
			<Title>
				Your personal
				<br />
				course is ready
			</Title>
			<CallToAction>
				Download the app
				<br />
				to continue
			</CallToAction>
			<Button onClick={onClickStore}>
				<img alt="" src={AppleLogoIcon} />
				<p>App Store</p>
			</Button>
			<Button onClick={onClickStore}>
				<img alt="" src={AndroidLogoIcon} />
				<p>Google Play</p>
			</Button>
			<Rating>
				<img alt="" src={FourHalfStarsNumberIcon} />
				{/* <p>4.8</p> */}
			</Rating>
			<Downloads>
				<div>
					<p>1M+</p>
					<small>Downloads</small>
				</div>
				<div>
					<p>12K</p>
					<small>5-star ratings</small>
				</div>
			</Downloads>
			<Subtitle>
				What do you get
				<br />
				with Glowbe?
			</Subtitle>
			<Cards>
				<img alt="" src={GreenCard} />
				<img alt="" src={PurpleCard} />
				<img alt="" src={RedCard} />
				<img alt="" src={OrangeCard} />
			</Cards>
			<Subtitle>Users About Us</Subtitle>
			<Reviews>
				<div>
					<img alt="" src={QuoteIcon} />
					<p>
						The exercises and massages are simple, so I enjoy doing them everyday. They have given me amazing results.
						Thank you so much for bringing them to us.
					</p>
					<div>
						<img alt="" src={Ivan} />
						<p>Ivan Morsi</p>
					</div>
				</div>
				<div>
					<img alt="" src={QuoteIcon} />
					<p>Evening beauty massage! I felt even more beautiful this evening</p>
					<div>
						<img alt="" src={Rosy} />
						<p>Rosy Walker</p>
					</div>
				</div>
				<div>
					<img alt="" src={QuoteIcon} />
					<p>It is very simple and you can see immediate results, no chemicals</p>
					<div>
						<img alt="" src={Sarah} />
						<p>Sarah Rosalie</p>
					</div>
				</div>
			</Reviews>
			{/* <Arrows>
				<Arrow>
					<img alt="" src={ArrowLeftIcon} />
				</Arrow>
				<Arrow isRotated>
					<img alt="" src={ArrowLeftIcon} />
				</Arrow>
			</Arrows> */}
			<Footer>Copyright © 2022 Glowbe. All rights reserved</Footer>
		</Wrapper>
	)

	// amplitudeService.logEvent("[Paddle Test] Paddle payment completed")

	// if (webPrepaywallType && product) {
	// 	return (

	// 	)
	// }
}
