import { useRxEffect } from "@anion155/rixio-utils"
import { Loader } from "@components/loader/loader"
import { useEffect, useMemo, useState } from "react"
import { useConfig } from "~/contexts/config-context"
import { useQuestions } from "~/contexts/questions-context/questions-context"
import { OnboardingQuestionRecord } from "~/contexts/questions-context/questions-types"
import { useQueryParams } from "~/hooks/use-query-params"
import { analytics } from "~/services/analytics"
import { navigatorLangCode } from "~/services/service/i18n-service"
import { usePreferenceContext } from "~/subscriptions-web/context"
import { currentUser$ } from "~/subscriptions-web/context/auth-context"
import * as server from "~/subscriptions-web/server-api"
import { HRollScreen } from "~/views/onboarding/navigation/hroll-screen/hroll-screen"

export const OnboardingNavScreen = () => {
	const remoteConfig = useConfig()
	const airQuestions = useQuestions()
	const splittedLangCode =
		navigatorLangCode === "zh-CN" || navigatorLangCode === "zh" ? "zh-CN" : navigatorLangCode.split("-")[0]

	const [localQuestions, setLocalQuestions] = useState([])

	useEffect(() => {
		if (airQuestions && airQuestions.questions && airQuestions.questions.length) {
			setLocalQuestions(
				airQuestions.questions.map((question: any) => ({
					attachments: question.attachments,
					id: question.id,
					progressbar: question.progressbar,
					type: question.type,
					label:
						splittedLangCode !== "en" && splittedLangCode !== "en-GB"
							? question[`label_${splittedLangCode}`] || question.label
							: question.label,
					options:
						splittedLangCode !== "en" && splittedLangCode !== "en-GB"
							? question[`options_${splittedLangCode}`] || question.options
							: question.options,
					// eslint-disable-next-line no-nested-ternary
					label_2: question.label_2
						? splittedLangCode !== "en" && splittedLangCode !== "en-GB"
							? question[`label_2_${splittedLangCode}`] || question.label_2
							: question.label_2
						: "",
					label_3: question.label_3 || "",
				}))
			)
		}
	}, [airQuestions, splittedLangCode, setLocalQuestions])

	const [saved, setSaved] = useState(false)

	const onboardingTypeUrl = useQueryParams().get("onboardingType")

	const onboardingType = !onboardingTypeUrl ? remoteConfig?.webOnboardingType : remoteConfig?.webOnboardingTypeMan

	const [questions, setQuestions] = useState([] as OnboardingQuestionRecord[])

	// Async sign in user anonymously
	useRxEffect(
		currentUser$,
		(currentUser) => {
			if (!currentUser) {
				server.signInAnonymously()
			}
		},
		[]
	)

	const { preferences, setPreferencesWithMerge, savePreferences, isSaving } = usePreferenceContext()

	// Save prefences to server when they are changed and are not empty
	useEffect(() => {
		if (Object.keys(preferences).length > 0 && currentUser$.get() && !saved) {
			savePreferences!()
			setSaved(true)
		}
	}, [preferences, savePreferences, saved])

	// Send events to amplitude when questions are loaded
	useEffect(() => {
		if (localQuestions && localQuestions.length) {
			setQuestions(localQuestions)
		}
	}, [localQuestions, onboardingType])

	useEffect(() => {
		const webSubscription = remoteConfig?.webSubscription

		if (webSubscription) {
			analytics.track("add_to_cart", webSubscription)
		}
	}, [remoteConfig?.webSubscription])

	const landingType = remoteConfig?.webLandingType

	useEffect(() => {
		console.log("_____LOCAL_____", landingType, questions, localQuestions, airQuestions, questions.length)
	}, [landingType, localQuestions, questions, airQuestions])

	return (
		<>
			{landingType && questions && questions.length ? (
				<HRollScreen
					questions={questions}
					landingType={landingType}
					savePrefs={setPreferencesWithMerge!}
					preferences={preferences}
					prefsAreSaving={isSaving}
				/>
			) : (
				<Loader />
			)}
		</>
	)
}
