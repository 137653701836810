// We use global context to save preferences changes localy and to the server.
// 1. setPreferencesWithMerge - saves changes localy.
//      When pass a field value, it overwrites the previous one,
//      When pass underfined field value, the previous value stays unchanged,
//      When pass null it deletes the previous value.
// 2. savePreferences - saves all values stored localy to the server.
// 3. preferences - preferences values, stored localy.
// 4. isSaved - indicater wheather the preferences object saved on the server.
// Any above method or value (1-4) can be called anywhere.

import React, { useContext, useMemo, useReducer } from "react"
import * as server from "../../server-api"
import { RESPONSE_CODE } from "../../server-api/response-codes"
import { UserPreferences } from "./user-preferences"

export interface PreferenceState {
	isLoaded: boolean
	isLoading: boolean
	loadError?: any

	isSaved: boolean
	isSaving: boolean
	saveSuccessResponse?: server.SavePreferencesResponse
	saveFailureResponse?: server.SavePreferencesResponse

	preferences: UserPreferences
}

const initialState: PreferenceState = {
	isLoaded: false,
	isLoading: false,
	loadError: undefined,

	isSaved: false,
	isSaving: false,
	saveSuccessResponse: undefined,
	saveFailureResponse: undefined,

	preferences: {},
}

export interface PreferenceActions {
	loadPreferences: () => Promise<void>
	savePreferences: () => Promise<server.SavePreferencesResponse>

	setPreferencesWithMerge: (newPref: UserPreferences) => Promise<void>
}

export type PreferenceContext = PreferenceState & Partial<PreferenceActions>

enum ACTION_TYPE {
	LOAD_START,
	LOAD_FINISH,

	SAVE_START,
	SAVE_FINISH,

	SET_PREFERENCES,
}

type DispatchAction =
	| {
			type: ACTION_TYPE.LOAD_START
	  }
	| {
			type: ACTION_TYPE.LOAD_FINISH
			payload: {
				preferences: UserPreferences
				error?: any
			}
	  }
	| {
			type: ACTION_TYPE.SAVE_START
	  }
	| {
			type: ACTION_TYPE.SAVE_FINISH
			payload: {
				saveSuccessResponse?: server.SavePreferencesResponse
				saveFailureResponse?: server.SavePreferencesResponse
			}
	  }
	| {
			type: ACTION_TYPE.SET_PREFERENCES
			payload: {
				preferences: UserPreferences
			}
	  }

type Reducer = (prevState: PreferenceState, action: DispatchAction) => PreferenceState
type Dispatch = React.Dispatch<DispatchAction>

const reducer: Reducer = (prevState, action) => {
	switch (action.type) {
		case ACTION_TYPE.LOAD_START:
			return {
				...prevState,
				isLoading: true,
				loadError: undefined,
			}
		case ACTION_TYPE.LOAD_FINISH:
			return {
				...prevState,
				isLoading: false,
				isLoaded: !!action.payload.error,
				loadError: action.payload.error,
			}
		case ACTION_TYPE.SAVE_START:
			return {
				...prevState,
				isSaving: true,
				saveSuccessResponse: undefined,
				saveFailureResponse: undefined,
			}
		case ACTION_TYPE.SAVE_FINISH:
			return {
				...prevState,
				isSaving: false,
				isSaved: !!action.payload.saveSuccessResponse,
				saveSuccessResponse: action.payload.saveSuccessResponse,
				saveFailureResponse: action.payload.saveFailureResponse,
			}
		case ACTION_TYPE.SET_PREFERENCES:
			return {
				...prevState,
				preferences: {
					...prevState.preferences,
					...action.payload.preferences,
				},
				isSaved: false,
			}
		default:
			return prevState
	}
}

const createPreferenceContext = (
	reducer: Reducer,
	actions: Record<string, (state: PreferenceState, dispatch: Dispatch) => any>,
	initialState: PreferenceState
) => {
	const PreferenceContext = React.createContext<PreferenceContext>(initialState)

	const PreferenceProvider = ({ children }: any) => {
		//const [state$, dispatch] = useRxReducer(reducer, initialState)
		const [state, dispatch] = useReducer(reducer, initialState)

		//actions === { addBlogPost: (dispatch) => { return () => {dispatch())}
		const boundActions: any = {}
		for (let key in actions) {
			const actionCreator = actions[key]
			// eslint-disable-next-line react-hooks/rules-of-hooks
			boundActions[key] = useMemo(() => actionCreator(state, dispatch), [actionCreator, state, dispatch])
		}
		const contextValue = {
			...state,
			...boundActions,
		}

		return <PreferenceContext.Provider value={contextValue}>{children}</PreferenceContext.Provider>
	}

	const usePreferenceContext = (): PreferenceContext => {
		return useContext<PreferenceContext>(PreferenceContext)
	}

	return { PreferenceProvider, usePreferenceContext }
}

const loadPreferences = (state: PreferenceState, dispatch: Dispatch) => async () => {
	//TODO: Load preferences
}

/**
 * Saves preferences to server and makes request to change state.
 * Use state variables to render UI dependable on state change.
 * Use hooks to do method calls dependable on state change.
 */
const savePreferences = (state: PreferenceState, dispatch: Dispatch) => async () => {
	try {
		dispatch({ type: ACTION_TYPE.SAVE_START })

		const response = await server.savePreferences({
			...state.preferences,
		})

		if (response.code === RESPONSE_CODE.SUCCESS) {
			dispatch({
				type: ACTION_TYPE.SAVE_FINISH,
				payload: {
					saveSuccessResponse: response,
				},
			})
		} else {
			console.error(response.message)
			dispatch({
				type: ACTION_TYPE.SAVE_FINISH,
				payload: {
					saveFailureResponse: response,
				},
			})
		}
		return response
	} catch (error) {
		console.error((error as any).message)
		const response: server.SavePreferencesResponse = {
			code: RESPONSE_CODE.UNEXPECTED_ERROR,
			message: `Unexpected error ${(error as any).message}`,
		}
		dispatch({
			type: ACTION_TYPE.SAVE_FINISH,
			payload: {
				saveFailureResponse: response,
			},
		})
		return response
	}
}
/**
 * Make request to change state.
 * Use state variables to render UI dependable on state change.
 * Use hooks to do method calls dependable on state change.
 */
const setPreferencesWithMerge = (state: PreferenceState, dispatch: Dispatch) => async (newPref: UserPreferences) => {
	console.log("PREFS", state)
	dispatch({
		type: ACTION_TYPE.SET_PREFERENCES,
		payload: {
			preferences: {
				...state.preferences,
				...newPref,
			},
		},
	})
}

export const { PreferenceProvider, usePreferenceContext } = createPreferenceContext(
	reducer,
	{
		loadPreferences,
		savePreferences,
		setPreferencesWithMerge,
	},
	initialState
)
