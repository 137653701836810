import { Trans } from "@lingui/macro"
import styled, { css } from "styled-components"

import Facebook from "~/assets/Facebook.svg"
import Instagram from "~/assets/Instagram.svg"

interface IFooterProps {
	minimal?: boolean
}

const StyledFooter = styled.div<{ minimal?: boolean }>`
	position: relative;
	width: 100%;
	height: ${({ minimal }) => (minimal ? "auto" : "202px")};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: ${({ minimal }) => minimal && "center"};

	color: #1c1b47;
	text-align: left;

	box-sizing: border-box;

	padding: 0 20px;

	z-index: 100;

	margin-top: 24px;

	@media (orientation: landscape) {
		height: 116px;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;

		padding: 0 80px;
	}
`

const Socials = styled.div``

const Buttons = styled.div<{ minimal?: boolean }>`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	text-align: right;
	letter-spacing: -0.03em;

	color: #1c1b47;

	${({ minimal }) =>
		minimal
			? css`
					width: 300px !important;
					box-sizing: border-box;
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-between;
					align-items: center;

					> p {
						margin: 0;
						color: #000 !important;
						font-weight: 500 !important;
						font-size: 18px !important;
					}
			  `
			: ""};
	@media (orientation: landscape) {
		width: 100%;
	}

	> a {
		display: inline-block;
		margin-right: 8px;
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;

		letter-spacing: -0.03em;

		color: #1c1b47;

		@media (orientation: landscape) {
			margin: 0 0 8px;
		}

		@media (max-width: 320px) {
			font-size: 14px;
		}
	}
`

const Details = styled.div`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	text-align: right;
	letter-spacing: -0.03em;

	color: #828691;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		width: 100%;

		letter-spacing: -0.03em;

		color: #828691;

		text-align: left;
	}

	> div {
		display: flex;
		justify-content: space-evenly;
		width: 100%;

		flex-direction: row;

		> a {
			text-decoration: none;
			font-family: Inter, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 26px;

			letter-spacing: -0.03em;

			color: #1c1b47;
			/* color: #828691; */
			margin: 10px 0 0 0;
		}
	}

	> a {
		text-decoration: none;

		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;

		letter-spacing: -0.03em;

		color: #828691;
	}
`

const DetailsBreak = styled.br`
	@media (orientation: landscape) {
		display: none;
	}
`

const termsLink = "/terms.pdf"
const privacyLink = "/privacy.pdf"

export function Footer(props: IFooterProps) {
	return (
		<StyledFooter minimal={props.minimal}>
			{!props.minimal && (
				<Socials>
					<a href="https://www.instagram.com/glowbeapp/" target="_blank" rel="noreferrer">
						<img alt="" src={Instagram} />
					</a>
					<a href="https://www.facebook.com/Face-Care-101255138054295/" target="_blank" rel="noreferrer">
						<img alt="" src={Facebook} />
					</a>
				</Socials>
			)}
			<Details>
				<Buttons minimal={props.minimal}>
					<a href={termsLink} target="_blank" rel="noreferrer">
						<Trans>Terms & Conditions</Trans>
					</a>
					<a href={privacyLink} target="_blank" rel="noreferrer">
						<Trans>Privacy Policy</Trans>
					</a>
				</Buttons>
				{!props.minimal && (
					<p style={{ textAlign: "center" }}>
						{/* // Copyright ® 2019-2022 Glowbe. <DetailsBreak /> */}
						<Trans>Copyright ® 2019-2022 Glowbe.</Trans> <DetailsBreak />
						{/* // All Rights Reserved */}
						<Trans>All Rights Reserved</Trans>
					</p>
				)}
			</Details>
		</StyledFooter>
	)
}
