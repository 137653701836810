// import { firebaseApp } from "~/services/firebase/firebase-app"
import { firebaseApp } from "~/services/firebase/firebase-app"
import { RESPONSE_CODE } from "./response-codes"

export interface UpdateAnonymousUserEmailResponse {
	code:
		| RESPONSE_CODE.UNEXPECTED_ERROR
		| RESPONSE_CODE.NOT_FOUND
		| RESPONSE_CODE.FORBIDDEN
		| RESPONSE_CODE.USER_EXISTS
		| RESPONSE_CODE.BAD_REQUEST
		| RESPONSE_CODE.SUCCESS

	message: string

	data?: {
		error?: any
	}
}
/**
 * Upgrades Anonymous Account to Not Verified Account.
 * An email will not be sent to the original email as
 * it was not set for Anonymous user and protection for hijacking is not applied.
 * When account successfully upgrades The current user will be reloaded.
 * @param newEmail The new email address.
 */
export const updateAnonymousUserEmail = async (newEmail: string): Promise<UpdateAnonymousUserEmailResponse> => {
	try {
		const currentUser = firebaseApp.auth().currentUser
		if (!currentUser) {
			return {
				code: RESPONSE_CODE.NOT_FOUND,
				message: "There is no user to specify email. Sign in first.",
			}
		}

		if (!currentUser.isAnonymous) {
			return {
				code: RESPONSE_CODE.FORBIDDEN,
				message: "To specify email without verification user should be anonymous.",
			}
		}

		console.log("UPDATE EMAIL", newEmail)
		// alert(newEmail)
		await currentUser.updateEmail(newEmail)
		// alert("good")
		return {
			code: RESPONSE_CODE.SUCCESS,
			message: "User email is successfully specified.",
		}
	} catch (error) {
		const errorCode = (error as any).code
		const errorMessage = (error as any).message
		// More error codes https://firebase.google.com/docs/auth/admin/errors?hl=uk
		if (errorCode === "auth/email-already-exists" || errorCode === "auth/email-already-in-use") {
			// Email is already used by another user
			return {
				code: RESPONSE_CODE.USER_EXISTS,
				message: "Email is already used by another user.",
				data: {
					error,
				},
			}
		} else if (errorCode === "auth/invalid-email") {
			// Email used is invalid
			console.error(error)
			return {
				code: RESPONSE_CODE.BAD_REQUEST,
				message: "Email address is invalid.",
				data: {
					error,
				},
			}
		}
		console.error(error)
		return {
			code: RESPONSE_CODE.UNEXPECTED_ERROR,
			message: `Unexpected error. ${errorMessage}`,
			data: {
				error,
			},
		}
	}
}
