import { Trans } from "@lingui/macro"
import { useCallback, useRef, useState } from "react"
import styled from "styled-components"

import BlueBurger from "~/assets/BlueBurger.svg"
import Burger from "~/assets/Burger.svg"
import Close from "~/assets/Close.svg"
import Logo from "~/assets/Logo.svg"
import { Footer } from "../Footer"
import { TextBody } from "../Text"

export interface IHeaderProps {
	onLoad?: () => void
	simplified?: boolean
	desktopSimplified?: boolean
	lightBurger?: boolean
	absolute?: boolean
}

const StyledHeader = styled.div<{ simplified?: boolean; absolute?: boolean }>`
	position: absolute;
	/* position: ${({ absolute }) => (absolute ? "absolute" : "fixed")}; */
	top: 0;
	right: 0;
	width: ${({ simplified }) => (simplified ? "80px" : "100%")};
	height: 64px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	overflow: hidden;

	z-index: 200;

	@media (orientation: landscape) {
		width: 100%;
		height: 136px;
	}
`

const TopBar = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 64px;

	padding: 0 20px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	box-sizing: border-box;

	z-index: 50;

	@media (orientation: landscape) {
		padding: 50px 80px;
	}
`

const Menu = styled.div`
	position: fixed;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	background-color: #f6f6f6;

	z-index: 100;

	transition: left 0.4s;
`

const MenuItems = styled.div`
	margin-top: 64px;
	padding: 14px 20px;

	color: #828691;
`

const RoundButton = styled.div`
	position: relative;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 32px;
`

const BurgerWrapper = styled(RoundButton)<{ simplified?: boolean; light?: boolean }>`
	position: fixed;
	top: 20px;
	right: 20px;
	@media (orientation: landscape) {
		display: none;
	}

	background-color: ${({ simplified, light }) => (simplified || light ? "#e9e9f3" : "#706efd")};
`

const CloseWrapper = styled(RoundButton)`
	background-color: #e9e9f3;
`

const HeaderButtons = styled.div<{ simplified?: boolean }>`
	@media (orientation: portrait) {
		display: none;
	}

	display: flex;
	flex-direction: row;
	align-items: center;

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 34px;

		text-align: right;
		letter-spacing: -0.03em;

		color: ${({ simplified }) => (simplified ? "#B7B9C0" : "#fefefc")};

		margin: 16px;

		cursor: pointer;
	}

	> span {
		padding: 6px 12px;

		background: #f6f6f6;

		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;

		text-align: center;
		letter-spacing: -0.03em;

		color: #1c1b47;

		border-radius: 16px;

		margin-left: 16px;

		cursor: pointer;
	}
`

const LogoWrapper = styled.img<{ simplified?: boolean }>`
	@media (orientation: portrait) {
		display: ${({ simplified }) => (simplified ? "none" : "block")};
	}

	@media (orientation: landscape) {
		display: block;
	}
`

export function Header(props: IHeaderProps) {
	const [expanded, setExpanded] = useState(false)
	const headerRef = useRef<HTMLDivElement>(null)
	const menuRef = useRef<HTMLDivElement>(null)

	// const [loaded, setLoaded] = useState(false)
	//
	// useEffect(() => {
	// 	if (loaded && props.onLoad) {
	// 		return props.onLoad(loaded)
	// 	}
	// }, [loaded])

	const onClickBurger = () => {
		setTimeout(() => {
			setExpanded(true)
			if (headerRef && headerRef.current) {
				headerRef.current.style.width = "100%"
				headerRef.current.style.height = "100%"
			}
			if (menuRef && menuRef.current) {
				menuRef.current.style.left = "0%"
			}
		}, 0)
	}

	const onClickClose = () => {
		if (menuRef && menuRef.current) {
			menuRef.current.style.left = "100%"
		}
		setTimeout(() => {
			setExpanded(false)
			if (headerRef && headerRef.current) {
				if (props.simplified) {
					headerRef.current.style.width = "80px"
				}
				headerRef.current.style.height = "64px"
			}
		}, 400)
	}

	const onLoad = useCallback(() => {
		if (props.onLoad) {
			props.onLoad()
		}
	}, [props])

	return (
		<StyledHeader ref={headerRef} simplified={props.simplified} onLoad={onLoad} absolute={props.absolute}>
			<TopBar>
				<LogoWrapper simplified={props.simplified} alt="" src={Logo} />
				{!expanded && (
					<BurgerWrapper light={props.lightBurger} simplified={props.simplified} onClick={onClickBurger}>
						<img alt="" src={props.simplified || props.lightBurger ? BlueBurger : Burger} />
					</BurgerWrapper>
				)}
				<HeaderButtons simplified={props.desktopSimplified}>
					<p>
						<Trans>English</Trans>
					</p>
					<p>
						<Trans>FAQ</Trans>
					</p>
					<p>
						<Trans>Blog</Trans>
					</p>

					{!props.desktopSimplified && (
						<>
							<p>
								<Trans>Log In</Trans>
							</p>
							<span>
								<Trans>Sign Up Free</Trans>
							</span>
						</>
					)}
				</HeaderButtons>
			</TopBar>
			<Menu ref={menuRef}>
				<TopBar>
					<img alt="" src={Logo} />
					<CloseWrapper onClick={onClickClose}>
						<img alt="" src={Close} />
					</CloseWrapper>
				</TopBar>
				<MenuItems>
					<TextBody size="small" color="#828691">
						<Trans>English</Trans>
					</TextBody>
					<TextBody size="small" color="#828691">
						<Trans>FAQ</Trans>
					</TextBody>
					<TextBody size="small" color="#828691">
						<Trans>Blog</Trans>
					</TextBody>
				</MenuItems>
				<Footer />
			</Menu>
		</StyledHeader>
	)
}
