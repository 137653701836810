import { useHistory } from "react-router"
import { useQueryParams } from "~/hooks/use-query-params"
import { EchoLanding } from "./echo-landing"

export const EchoLandingContainer = () => {
	const history = useHistory()
	const onboardingType = useQueryParams().get("onboardingType")

	const onNext = () => {
		history.push(`/personal-course${onboardingType ? `?onboardingType=${onboardingType}` : ""}`)
	}

	return <EchoLanding onNext={onNext} onboardingType={onboardingType} />
}
