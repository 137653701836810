import { RadioBackButton } from "@components/buttons"
import { ProgressLine } from "@components/progress"
import { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { useQuestions } from "~/contexts/questions-context/questions-context"
import { OnboardingQuestionRecord } from "~/contexts/questions-context/questions-types"
import { useQueryParams } from "~/hooks/use-query-params"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DLandingType } from "~/services/config/config-types"
import { UserPreferences } from "~/subscriptions-web/context/preferences-context/user-preferences"
import { OnboardingPaywallScreen } from "~/views/onboarding/navigation/onboarding-screen/onboarding-paywall-page"
import { CurrentStep } from "./components/current-step"

import {
	Wrapper,
	PrevStepWrapper,
	CurrentStepWrapper,
	FirstStepWrapper,
	NextStepWrapper,
	ProgressWrapper,
	DesktopProgressWrapper,
	BackButtonWrapper,
} from "./hroll-screen.styles"

export interface IHRollScreenProps {
	questions: OnboardingQuestionRecord[]
	landingType: DLandingType
	savePrefs: (newPref: UserPreferences) => Promise<void>
	prefsAreSaving: boolean
	preferences: UserPreferences
}

export const HRollScreen = ({ questions, landingType, savePrefs, prefsAreSaving, preferences }: IHRollScreenProps) => {
	const size = useWindowLayout()

	const isDeltaLanding = landingType === DLandingType.Delta || landingType === DLandingType.Echo

	// State for navigation position in questions
	const [currentStep, setCurrentStep] = useState(0)
	const [nextStep, setNextStep] = useState(1)
	const [prevStep, setPrevStep] = useState<number | null>(null)
	const history = useHistory()
	const onboardingType = useQueryParams().get("onboardingType")

	// State/Refs for navigation animation
	const [transitionEnabled, setTransitionEnabled] = useState(false)
	const PrevStepRef = useRef<HTMLDivElement>(null)
	const CurrentStepRef = useRef<HTMLDivElement>(null)
	const NextStepRef = useRef<HTMLDivElement>(null)

	const [screen, setScreen] = useState(undefined as string | undefined)
	const onboardingTypeURL = useQueryParams().get("onboardingType")

	// let historyCurrentStep = 0
	// let historyPrevStep = 0

	useEffect(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const questionNumber = urlParams.get("q")
		const prevQuestionNumber = urlParams.get("p")

		setScreen("onboarding")

		if ((questionNumber || questionNumber === "0") && questionNumber !== undefined) {
			if (
				(questionNumber || questionNumber === "0") &&
				questionNumber !== undefined &&
				+questionNumber >= questions.length
			) {
				setScreen("paywall")
				setCurrentStep(+questionNumber - 1)
				// historyCurrentStep = +questionNumber - 1

				setTimeout(() => {
					if (prevQuestionNumber && prevQuestionNumber !== undefined) {
						setPrevStep(+prevQuestionNumber)
						// historyPrevStep = +prevQuestionNumber
					}
					console.log("+++PAYWALL SET+++", screen, questionNumber, +questionNumber >= questions.length)
					return
				}, 1000)
			}
			if (questionNumber === "0") {
				history.push(`/personal-course${onboardingTypeURL ? `?onboardingType=${onboardingTypeURL}` : ""}`)
				setScreen("onboarding")
			}

			setCurrentStep(+questionNumber)
			// historyCurrentStep = +questionNumber

			if (prevQuestionNumber && prevQuestionNumber !== undefined) {
				setPrevStep(+prevQuestionNumber)
				// historyPrevStep = +prevQuestionNumber
			}
		} else {
			setCurrentStep(0)
		}
	}, [questions.length, history, onboardingTypeURL, screen])

	useEffect(() => {
		if (currentStep < questions.length) {
			console.log("___CURRENT STEP___", questions[currentStep])
			amplitudeService.logEvent("[All] Onboarding | Page", { id: questions[currentStep].id })
		}
	}, [currentStep, questions])

	// Syncronization between currentStep and browser history object, nextStep, prevStep
	// When currentStep changes then change history, nextStep, prevStep
	useEffect(() => {
		if (currentStep >= questions.length) {
			const isQuestionToBeMissedInHistory = (question: OnboardingQuestionRecord) => {
				if (
					question.type === "loader" ||
					question.type === "finish_loader_checklist" ||
					question.type === "finish_loader"
				) {
					return true
				}

				return false
			}

			const getPrevStepForNewCurrStep = (histCurrStep: number | undefined, histPrevStep: number | undefined) => {
				if (histCurrStep !== undefined) {
					if (isQuestionToBeMissedInHistory(questions[histCurrStep])) {
						return histPrevStep === undefined ? null : histPrevStep
					}
					return histCurrStep
				}

				return null
			}

			const historyCurrentStep = globalThis.history.state ? globalThis.history.state.currentStep : currentStep
			const historyPrevStep = globalThis.history.state ? globalThis.history.state.prevStep : prevStep

			if (currentStep !== historyCurrentStep) {
				// It happens only if we move forward by increementing currentStep,
				// because in other cases history change changes currentStep and they are equal
				const newPrevStep = getPrevStepForNewCurrStep(historyCurrentStep, historyPrevStep)

				setPrevStep(newPrevStep)
				setNextStep(currentStep + 1)
				if (
					(historyCurrentStep !== undefined &&
						historyCurrentStep !== null &&
						isQuestionToBeMissedInHistory(questions[historyCurrentStep])) ||
					globalThis.location.search === undefined ||
					globalThis.location.search === ""
				) {
					globalThis.history.replaceState(
						{ currentStep, prevStep: newPrevStep },
						`onboarding-${currentStep}`,
						globalThis.location.origin +
							`${globalThis.location.pathname}?q=${currentStep}&p=${newPrevStep}
						${onboardingType ? `&onboardingType=${onboardingType}` : ""}`
					)
				} else {
					globalThis.history.pushState(
						{ currentStep, prevStep: newPrevStep },
						`onboarding-${currentStep}`,
						globalThis.location.origin +
							`${globalThis.location.pathname}?q=${currentStep}&p=${newPrevStep}${
								onboardingType ? `&onboardingType=${onboardingType}` : ""
							}`
					)
				}
			}
		}
	}, [currentStep, questions, setNextStep, setPrevStep, onboardingType, prevStep])

	// Syncronization between browser history object and currentStep, nextStep, prevStep
	// When history changes then change current step
	useEffect(() => {
		const listener = (e: PopStateEvent) => {
			if (e.state && e.state.currentStep !== undefined) {
				if (e.state.currentStep < currentStep) {
					// when move backward
					setTransitionEnabled(true)
					if (CurrentStepRef.current) {
						CurrentStepRef.current.style.left = "100%"
					}
					if (PrevStepRef.current) {
						PrevStepRef.current.style.left = "0"
					}

					setTimeout(() => {
						setTransitionEnabled(false)

						if (prevStep === null) {
							return
						}

						setCurrentStep(e.state.currentStep)
						setPrevStep(e.state.prevStep)
						setNextStep((e.state.currentStep as number) + 1)

						if (CurrentStepRef.current) {
							CurrentStepRef.current.style.left = "0"
						}
						if (PrevStepRef.current) {
							PrevStepRef.current.style.left = "-100%"
						}
					}, 300)
				} else {
					// when move forward by history button
					if (CurrentStepRef.current && NextStepRef.current) {
						setNextStep(e.state.currentStep)
						setTransitionEnabled(true)

						CurrentStepRef.current.style.left = "-100%"
						NextStepRef.current.style.left = "0"
					}

					setTimeout(() => {
						setTransitionEnabled(false)

						if (CurrentStepRef.current) {
							CurrentStepRef.current.style.left = "0"
						}
						if (NextStepRef.current) {
							NextStepRef.current.style.left = "100%"
						}
						setCurrentStep(e.state.currentStep)
						setPrevStep(e.state.prevStep)
						setNextStep((e.state.currentStep as number) + 1)
					}, 300)
				}
			} else {
				//history.goBack()
			}
		}
		window.addEventListener("popstate", listener)
		return () => {
			window.removeEventListener("popstate", listener)
		}
	}, [history, CurrentStepRef, PrevStepRef, NextStepRef, prevStep, currentStep])

	// Delta Landing animation status
	const [deltaLandingFinished, setDeltaLandingFinished] = useState(false)
	// If it's delta landing then prepare first screen for a specific animation (from bottom)
	useEffect(() => {
		if (isDeltaLanding && currentStep === 0 && CurrentStepRef.current && !deltaLandingFinished) {
			CurrentStepRef.current.style.bottom = "0"
			CurrentStepRef.current.style.opacity = "100"

			setTimeout(() => {
				setDeltaLandingFinished(true)
			}, 300)
		}

		return () => {}
	}, [currentStep, deltaLandingFinished, isDeltaLanding, setDeltaLandingFinished])

	const allAreas = [...useQuestions().areas]
	const allGoals = [...useQuestions().goals]

	const moveForward = async (props?: { skip?: number }) => {
		const skip = props?.skip ?? 0

		const localAreas = localStorage.getItem("areasIds")
		const localGoals = localStorage.getItem("goalsIds")

		// Save preferences locally if current step is the last one
		if (currentStep === questions.length - 1) {
			const areas = localAreas ? localAreas.split(", ") : allAreas.map((area) => area.id)
			const goals = localGoals ? localGoals.split(", ") : allGoals.map((goal) => goal.id)

			// Saves async
			savePrefs!({
				name: "",
				email: localStorage.getItem("email") || "",
				areas,
				goals,
			})
		}

		if (CurrentStepRef.current && NextStepRef.current) {
			await setTransitionEnabled(true)
			setNextStep((prev) => prev + (skip ?? 0))

			CurrentStepRef.current.style.left = "-100%"
			NextStepRef.current.style.left = "0"
		}

		setTimeout(async () => {
			await setTransitionEnabled(false)

			if (CurrentStepRef.current && NextStepRef.current) {
				if (CurrentStepRef.current) {
					CurrentStepRef.current.style.left = "0"
				}
				if (NextStepRef.current) {
					NextStepRef.current.style.left = "100%"
				}
			}

			setCurrentStep((prevStep) => {
				return prevStep + 1 + skip
			})
		}, 300)
	}

	const moveBackward = useCallback(() => {
		// globalThis.history.back()
		setCurrentStep((prevStep) => {
			return prevStep - 1
		})
	}, [])

	const getProgress = useCallback(() => ((currentStep + 1) / questions.length) * 100, [currentStep, questions])

	const isTopBarVisible = (questions: OnboardingQuestionRecord[]) =>
		questions[currentStep] &&
		questions[currentStep].type !== "loader" &&
		questions[currentStep].type !== "finish_loader_checklist" &&
		questions[currentStep].type !== "finish_loader"

	if (currentStep >= questions.length) {
		return (
			<div ref={NextStepRef}>
				<OnboardingPaywallScreen onPrev={moveBackward} />
			</div>
		)
	}

	return (
		<Wrapper id="OnboardingWrapper">
			<>
				{isTopBarVisible(questions) && (
					<>
						{currentStep > 0 && (
							<BackButtonWrapper size={size}>
								<RadioBackButton size={size} isDisabled={prefsAreSaving} onClick={moveBackward} />
							</BackButtonWrapper>
						)}
						{size === "Mobile" && (
							<ProgressWrapper>
								<ProgressLine size={size} progress={getProgress()} />
							</ProgressWrapper>
						)}
					</>
				)}
				{prevStep !== null && (
					<PrevStepWrapper size={size} ref={PrevStepRef} transitionEnabled={size === "Mobile" && transitionEnabled}>
						<CurrentStep
							questions={questions}
							step={prevStep}
							isCurrent={false}
							currentStep={currentStep}
							isSaving={prefsAreSaving}
							moveForward={moveForward}
							preferences={preferences}
						/>
					</PrevStepWrapper>
				)}
				{currentStep < questions.length &&
					(!isDeltaLanding || (isDeltaLanding && (currentStep !== 0 || deltaLandingFinished))) && (
						<CurrentStepWrapper
							size={size}
							ref={CurrentStepRef}
							transitionEnabled={size === "Mobile" && transitionEnabled}
							isTopBarHidden={!isTopBarVisible(questions)}
						>
							<CurrentStep
								questions={questions}
								step={currentStep}
								isCurrent={true}
								currentStep={currentStep}
								isSaving={prefsAreSaving}
								moveForward={moveForward}
								preferences={preferences}
							/>
						</CurrentStepWrapper>
					)}
				{currentStep < questions.length && isDeltaLanding && !deltaLandingFinished && currentStep === 0 && (
					<FirstStepWrapper ref={CurrentStepRef} transitionEnabled={size === "Mobile"}>
						<CurrentStep
							questions={questions}
							step={currentStep}
							isCurrent={true}
							currentStep={currentStep}
							isSaving={prefsAreSaving}
							moveForward={moveForward}
							preferences={preferences}
						/>
					</FirstStepWrapper>
				)}
				{questions[nextStep] && (
					<NextStepWrapper size={size} ref={NextStepRef} transitionEnabled={size === "Mobile" && transitionEnabled}>
						<CurrentStep
							questions={questions}
							step={nextStep}
							isCurrent={false}
							currentStep={currentStep}
							isSaving={prefsAreSaving}
							moveForward={moveForward}
							preferences={preferences}
						/>
					</NextStepWrapper>
				)}
				{currentStep >= questions.length && (
					<div ref={NextStepRef}>
						<OnboardingPaywallScreen onPrev={moveBackward} />
					</div>
				)}
				{isTopBarVisible(questions) && size === "Desktop" && (
					<DesktopProgressWrapper>
						<ProgressLine size={size} progress={getProgress()} />
					</DesktopProgressWrapper>
				)}
			</>
		</Wrapper>
	)
}
