import { Trans } from "@lingui/macro"
import { useEffect } from "react"
import { ProgressStatus, useUserStatus } from "~/subscriptions-web/hooks"
import { useSignInWithOTP } from "~/subscriptions-web/hooks/use-signin-with-otp"

export const SignInCallbackPage = () => {
	const { userSignedInWithEmailVerified } = useUserStatus()
	const { signInWithOTP, signInWithOTPState } = useSignInWithOTP()

	useEffect(() => {
		if (!userSignedInWithEmailVerified && signInWithOTPState.status === ProgressStatus.START) {
			const code = new URLSearchParams(window.location.search).get("code") || "0"
			if (code) {
				signInWithOTP!(code)
			}
		}
	}, [userSignedInWithEmailVerified, signInWithOTPState, signInWithOTP])

	if (signInWithOTPState.status === ProgressStatus.WORKING) {
		return (
			<div>
				<Trans>Working</Trans>
			</div>
		)
	}

	return (
		<div>
			<Trans>loading ....</Trans>
			{signInWithOTPState.response?.message ?? ""}
			{userSignedInWithEmailVerified ? (
				<Trans>verified and signed in - true</Trans>
			) : (
				<Trans> verified and signed in - false</Trans>
			)}
		</div>
	)
}
