import { color, background } from "@components/shared/colors"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled, { keyframes, css } from "styled-components"
import { IActionButtonProps, State } from "./action-button"

export const rotate = keyframes`
		from {
			transform: rotate(0deg);
		}
	
		to {
			transform: rotate(360deg);
		}
		`

export const Spinner = styled.img`
	width: 28px;
	height: 28px;
	animation: ${rotate} 2s linear infinite;
`

export const StyledButton = styled.button<IActionButtonProps>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	outline: none;
	border: none;

	padding: ${spacing.padding.verticalSmall}px 0;
	border-radius: ${spacing.borderRadius.small}px;
	box-sizing: border-box;
	text-align: center;

	cursor: ${({ state }) => (state === State.Regular ? "pointer" : "not-allowed")};

	${({ size }) =>
		size === "Desktop"
			? css`
					font-family: ${typography.family.inter};
					font-style: ${typography.fontStyle.default};
					font-weight: ${typography.fontWeight.bolder};
					font-size: ${typography.fontSize.b1}px;
					line-height: ${typography.lineSize.b1}px;

					text-align: center;
					letter-spacing: ${typography.letterSpacing.b}em;

					height: 64px;
			  `
			: css`
					font-family: ${typography.family.inter};
					font-style: ${typography.fontStyle.default};
					font-weight: ${typography.fontWeight.bolder};
					font-size: ${typography.fontSize.bm1}px;
					line-height: ${typography.lineSize.bm1}px;

					text-align: center;
					letter-spacing: ${typography.letterSpacing.b}em;

					height: 56px;
			  `};

	transition: background 0.2s;

	/* TODO: what to do if button does not fit small screen? */
`

export const BlueButton = styled(StyledButton)<IActionButtonProps>`
	${({ isStretch, size }) =>
		isStretch
			? css`
					width: 100%;
			  `
			: css`
					width: ${size === "Desktop" ? "480px" : "335px"};
			  `};
	background: ${({ state }) => (state === State.Disabled ? background.moon : background.indigo)};

	color: ${color.white};

	box-shadow: ${({ state }) =>
		state !== State.Disabled && "0px 4px 12px rgba(0, 0, 0, 0.06), 0px 10px 32px rgba(88, 86, 214, 0.32)"};

	:hover {
		background: ${({ state }) => state === State.Regular && background.cornflower};
	}
`

export const PinkButton = styled(StyledButton)<IActionButtonProps>`
	${({ isStretch, size }) =>
		isStretch
			? css`
					width: 100%;
			  `
			: css`
					width: ${size === "Desktop" ? "360px" : "335px"};
			  `};
	background: ${background.pale};

	color: ${({ size }) => (size === "Mobile" ? color.yankees : color.black)};

	box-shadow: ${({ state }) =>
		state !== State.Disabled && "0px 4px 6px rgba(255, 209, 218, 0.08), 0px 24px 40px rgba(255, 209, 218, 0.2)"};

	:hover {
		background: ${({ state }) => state === State.Regular && background.pippin};
	}
`
