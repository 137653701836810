import styled, { keyframes } from "styled-components"
import LoaderIcon from "~/assets/LoaderIcon.svg"

export interface ILoaderProps {}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1200;
	background: #f6f6f6;

	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		width: 28px;
		height: 28px;

		animation: ${rotate} 2s linear infinite;
	}
`

export function Loader() {
	return (
		<Wrapper>
			<img alt="" src={LoaderIcon} />
		</Wrapper>
	)
}
