export enum SubscriptionStatus {
	DRAFT = "draft", // Subscription is not yet created, service is not provided.

	TRIALING = "trialing", // Subscription is in a trial period, service is provided, next payment will be at the end of period.
	ACTIVE = "active", // Subscription is in a regular period, service is provided, next payment will be at the end of period.
	CANCELLED = "canceled", // Subscription is in a trial or regular period, service is provided, next payment will NOT be at the end of period.

	INCOMPLETE = "incomplete", //  If the first/initial payment attempt fails.
	INCOMPLETE_EXPIRED = "incomplete_expired", //  If the first invoice is not paid within 23 hours, the subscription transitions to incomplete_expired

	PAST_DUE = "past_due", // Payment to renew subscription failed, next payment attempts will happen soon, service is not provided.
	UNPAID = "unpaid", // Payment to renew subscription failed, all payments attempts are run out, service is not provided.
	CLOSED = "closed", // Service is not provided due to cancel in a previous period.

	ERROR = "error", // Status for reserved cases
}

export const isSubscriptionStatusToProvideService = (status: SubscriptionStatus): boolean => {
	if (
		status === SubscriptionStatus.TRIALING ||
		status === SubscriptionStatus.ACTIVE ||
		status === SubscriptionStatus.CANCELLED
	) {
		return true
	}

	return false
}

export const isSubscriptionStatusToSuspendService = (status: SubscriptionStatus): boolean => {
	if (status === SubscriptionStatus.PAST_DUE) {
		return true
	}

	return false
}

export enum SubscriptionProvider {
	Paypal = "paypal",
	Stripe = "stripe",
	Paddle = "paddle",
}

export interface Subscription {
	id: string
	periodStart?: Date
	periodEnd?: Date
	status?: SubscriptionStatus
	provider?: SubscriptionProvider
	//TODO: productId and priceIs must be required
	planId?: string
	productId?: string
}
