import { useEffect } from "react"
import styled from "styled-components"
import { Header } from "~/components2/atoms/Header"
import { amplitudeService } from "~/services/amplitude"
import { DownloadAppScreen } from "./download-app-screen"

export interface IDownloadAppScreenContainerProps {
	onNext?: () => void
	onPrev?: () => void
}

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: #f6f6f6;
	box-sizing: border-box;

	z-index: 50;
`

export const DownloadAppScreenContainer = (props: IDownloadAppScreenContainerProps) => {
	useEffect(() => {
		amplitudeService.logEvent("[All] Success guide")
	}, [])

	useEffect(() => {
		console.log("download email", localStorage.getItem("email"))
	}, [])

	return (
		<Wrapper>
			<Header lightBurger desktopSimplified />
			<DownloadAppScreen onNext={props.onNext} onPrev={props.onPrev} />
		</Wrapper>
	)
}
