import { useEffect } from "react"
import { amplitudeService } from "~/services/amplitude"
import { useUpgradeAnonymousUser } from "~/subscriptions-web/hooks/use-upgrade-anonymous-user"
import { ConfirmEmailScreen } from "./confirm-email-screen"

export interface IConfirmEmailScreenContainerProps {
	currentEmail: string
	onNext: () => void
	onPrev?: () => void
}

export const ConfirmEmailScreenContainer = (props: IConfirmEmailScreenContainerProps) => {
	const { upgradeAnonymousUser } = useUpgradeAnonymousUser(props.onNext)
	useEffect(() => {
		amplitudeService.logEvent("[All] Check email")
	}, [])

	const confirmEmail = async (email?: string) => {
		if (email) {
			localStorage.setItem("email", email)

			await upgradeAnonymousUser(email)
				.then(() => console.log("___SUCCESS UPDATING EMAIL___"))
				.catch(() => {
					console.log("email confirmation failed")
					console.log("___FAIL UPDATING EMAIL___")
				})

			amplitudeService.setIdentify((identify) => {
				identify.set("Email", email)
			})
			amplitudeService.logEvent("[All] Button | Confirm email", { email })

			props.onNext()
		}
	}

	return <ConfirmEmailScreen currentEmail={props.currentEmail} confirmEmail={confirmEmail} onNext={props.onNext} />
}
