import { useEffect, useReducer } from "react"
// import { firebaseApp } from "~/services/firebase/firebase-app"
import { firebaseApp } from "~/services/firebase/firebase-app"
import * as server from "../server-api"
import { RESPONSE_CODE } from "../server-api/response-codes"
import { ProgressStatus } from "."

export interface State {
	status: ProgressStatus
	response?: server.GetSignInTokenResponse
}

const initialState: State = {
	status: ProgressStatus.START,
}

enum ACTION {
	STARTED,
	FINISHED,
}

const reducer = (
	prevState: State,
	action:
		| {
				type: ACTION.STARTED
		  }
		| {
				type: ACTION.FINISHED
				payload: server.GetSignInTokenResponse
		  }
) => {
	switch (action.type) {
		case ACTION.STARTED:
			return {
				...prevState,
				status: ProgressStatus.WORKING,
			}
		case ACTION.FINISHED:
			return {
				...prevState,
				status: ProgressStatus.DONE,
				response: action.payload,
			}
	}
}

/**
 *  Requests the server for a token with one time password to sign in existing user.
 *  No one should be signed in at the moment of signInWithOTP method.
 *  The following 4 error codes should be proccessed, in other cases throw an error.
 *  Do not forget to watch after data.waitTime in case 2 and 3.
 *  1. RESPONSE_CODE.NOT_VALID
 *  2. RESPONSE_CODE.TOO_MANY_REQUESTS
 *  3. RESPONSE_CODE.INCORRECT
 *  4. RESPONSE_CODE.SUCCESS
 */
export const useSignInWithIdToken = (
	onSuccess?: (response?: server.GetSignInTokenResponse) => void,
	onError?: (response?: server.GetSignInTokenResponse) => void
) => {
	const [signInWithIdTokenState, dispatch] = useReducer(reducer, initialState)

	const signInWithIdToken = async (token: string) => {
		try {
			dispatch({ type: ACTION.STARTED })

			const response = await server.getMobileSignInToken({ token })
			if (response.data && response.data.token) {
				await firebaseApp.auth().signInWithCustomToken(response.data.token)
			}

			dispatch({ type: ACTION.FINISHED, payload: response })
		} catch (err) {
			dispatch({
				type: ACTION.FINISHED,
				payload: {
					code: RESPONSE_CODE.UNEXPECTED_ERROR,
					message: `Unexpected error while signInWithOTP ${(err as any).message ?? ""}`,
				},
			})
		}
	}

	useEffect(() => {
		if (signInWithIdTokenState.response) {
			if (signInWithIdTokenState.response.code === RESPONSE_CODE.SUCCESS) {
				if (onSuccess) {
					onSuccess(signInWithIdTokenState.response)
				}
			} else {
				if (onError) {
					onError(signInWithIdTokenState.response)
				}
			}
		}
	}, [signInWithIdTokenState.response, onSuccess, onError])

	return {
		signInWithIdToken,
		signInWithIdTokenState,
	}
}
