import { ChoiceButton } from "@components/buttons"
import { forwardRef, PropsWithChildren } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Label } from "./select-screen.styles"

export interface ISelectScreenProps {
	label: string
	options: string[]
	onNext: () => void
}

export const SelectScreen = forwardRef<unknown, PropsWithChildren<ISelectScreenProps & JSX.IntrinsicElements["div"]>>(
	({ ...rest }, ref: any) => {
		const size = useWindowLayout()

		return (
			<Wrapper ref={ref} size={size}>
				<Label size={size}>{rest.label}</Label>
				{rest.options.map((option, index) => (
					<ChoiceButton key={option} size={size} onClick={rest.onNext}>
						{option}
					</ChoiceButton>
				))}
			</Wrapper>
		)
	}
)
