import crypto from "crypto"
import Analytics from "analytics"
import { name } from "root/package.json"
import { userAgent } from "~/utils/platform"
import { DSubscriptionValue } from "../config/config-types"
import { facebookPixelPlugin } from "./facebook-pixel"
import { gtagPlugin } from "./gtag"
import { snapchatPixelPlugin } from "./snapchat-pixel"
import { pluginTrackWrap, TypedAnalytics } from "./wrapper"

export type AnalyticsEventsMap = {
	/* eslint-disable camelcase */
	sign_up: { email: string }
	add_to_cart: DSubscriptionValue
	purchase: { product: DSubscriptionValue; orderID: string }
	checkout: DSubscriptionValue
	/* eslint-enable camelcase */
}

const hashedEmail = crypto
	.createHash("sha256")
	.update(localStorage.getItem("email") || "")
	.digest("hex")

function parseProduct(product: DSubscriptionValue): ParsedProduct {
	return {
		currency: product && product.trial ? product.trial.currency.toUpperCase() : "",
		value: product && product.trial ? product.trial.amount / 100 : 0,
	}
}
type ParsedProduct = { currency: string; value: number }
type FacebookEventsMap = {
	AddToCart: ParsedProduct
	CompleteRegistration: void
	StartCheckout: ParsedProduct
	Purchase: ParsedProduct
}
type SnapchatEventsMap = {
	ADD_CART: ParsedProduct
	SIGN_UP: void
	START_CHECKOUT: ParsedProduct
	PURCHASE: ParsedProduct
}

export const analytics = Analytics({
	app: name,
	plugins: [
		gtagPlugin({
			measurementId: process.env.REACT_APP_GOOGLE_CONTAINER_ID!,
		}),
		pluginTrackWrap<AnalyticsEventsMap, FacebookEventsMap>()(
			facebookPixelPlugin({ pixelId: process.env.REACT_APP_FACEBOOK_PIXEL! }),
			{
				add_to_cart: (product) => ["AddToCart", parseProduct(product)],
				sign_up: "CompleteRegistration",
				checkout: (product) => ["StartCheckout", parseProduct(product)],
				purchase: ({ product }) => [
					"Purchase",
					{
						...parseProduct(product),
						// eslint-disable-next-line no-nested-ternary
						value: userAgent.Android || userAgent.iOS ? 10 : parseProduct(product).value,
					},
				],
			}
		),
		pluginTrackWrap<AnalyticsEventsMap, SnapchatEventsMap>()(
			snapchatPixelPlugin({ pixelId: process.env.REACT_APP_SNAPCHAT_PIXEL!, matching: { user_email: hashedEmail } }),
			{
				add_to_cart: (product) => ["ADD_CART", parseProduct(product)],
				sign_up: "SIGN_UP",
				checkout: (product) => ["START_CHECKOUT", parseProduct(product)],
				purchase: ({ product }) => ["PURCHASE", parseProduct(product)],
			}
		),
	],
}) as TypedAnalytics<AnalyticsEventsMap>
