// import { firebaseFunctions } from "~/services/firebase/firebase-app"

import { firebaseFunctions } from "~/services/firebase/firebase-app"

export const callFirebaseFunction = async <T, R>(functionName: string, params: T): Promise<R> => {
	const firebaseRequest = firebaseFunctions.httpsCallable(functionName)
	const response = await firebaseRequest(params)

	const data = response.data as R

	return data
}
