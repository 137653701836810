import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { EmailScreen } from "./email-screen"

export interface IEmailScreenContainerProps {
	label: string
	description: string
	onNext: (email: string) => void
}

export const EmailScreenContainer = (props: IEmailScreenContainerProps) => {
	const localOnNext = (email: string) => {
		amplitudeService.setIdentify((identify) => {
			identify.set("Email", email)
		})
		amplitudeService.logEvent("[All] Onboarding | Email", { email })
		analytics.track("sign_up", { email })

		props.onNext(email)
	}

	return <EmailScreen title={props.label} description={props.description} onNext={localOnNext} />
}
